/* Video Background */
.ReadyToStart .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0; /* Ensure the video stays behind the content */
}

/* Section Styling */
.sec-two.ReadyToStart {
  position: relative;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #fff;
  margin-top: 20px; 
  margin-bottom: 20px; 
}

.ReadyToStart .text-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2; /* Ensure the content stays above the video */
  padding: 20px 32px;
}

.ReadyToStart .text-box {
  max-width: 729px;
  margin: 0 auto;
}

.ReadyToStart .text-box h2 {
  font-size: 2.8rem;
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 20px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* opacity: 0;  */
}

.ReadyToStart .text-box h2 span {
  color: #c26de1; /* Gold highlight for the brand name */
}

.ReadyToStart .text-box p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #e2e8f0; /* Softer white for better contrast */
  /* opacity: 0;  */
}

.ReadyToStart .text-box .cta-button {
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: 600;
  color: #1e3a8a;
  background: #ffd700;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  opacity: 0; /* Start hidden for animation */
}

.ReadyToStart .text-box .cta-button:hover {
  transform: scale(1.1);
  background: #facc15; /* Brighter yellow on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .ReadyToStart .text-box h2 {
    font-size: 2rem;
  }

  .ReadyToStart .text-box p {
    font-size: 1rem;
  }

  .ReadyToStart .text-box .cta-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}
