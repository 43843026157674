.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .containerCursor {
  pointer-events: none;
  position: relative;
  mix-blend-mode: exclusion;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.wrapperCursor {
  position: absolute;
  top: -40px;
  left: -60px;
  width: 60px;
  height: 60px;
  will-change: transform;
  contain: layout style size;
} */

.cursor {
  overflow: visible;
}

::selection {
  color: #ffffff00;
}

.viewport {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  will-change: transform;
}

.cursor-dot {
  position: fixed;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border: 2px solid #170068;
  background-color: #67008b;
  border-radius: 50%;
  pointer-events: none; /* Prevent blocking clicks */
  z-index: 9999;
  mix-blend-mode: difference; /* Creates a cool effect over dark backgrounds */
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out, width 0.3s, height 0.3s;
}

.cursor-follower {
  position: fixed;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  pointer-events: none;
  z-index: 9998;
  transform: translate(-50%, -50%);
  mix-blend-mode: difference;
  transition: transform 0.3s ease-out, width 0.3s, height 0.3s;
}

a, button, .hover-effect {
  cursor: none; /* Hide the default cursor on hoverable elements */
}

/* Initial hidden state */
.fade-up {
  opacity: 0;
  transform: translateY(50px); /* Start from below */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Class added when the element becomes visible */
.fade-in-visible {
  opacity: 1;
  transform: translateY(0); /* Move into place */
}
