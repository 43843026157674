

#text-container {
    position: relative;
    font-family: 'Arial', sans-serif;
    font-size: 6rem;
    font-weight: bold;
    color: #fff;
}

.letter {
    display: inline-block;
    transition: transform 0.2s ease-out;
}

#cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    mix-blend-mode: difference;
    pointer-events: none;
    transform: translate(-50%, -50%);
}

#instruction {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-family: 'Arial', sans-serif;
    font-size: 1rem;
    color: #888;
    text-align: right;
}