@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Inter:wght@200;300;400;500;600;700&display=swap');
@import url('https://db.onlinewebfonts.com/c/ad230925aac0325de699bf05b6bdfa0c?family=LTC+Record+Title+W00+Regular');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
div#root {
  background: #130f13;
  scroll-behavior: smooth;
  overflow-x: auto;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family:"Fira Sans Condensed", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  text-decoration: none !important;
  /* color: inherit !important; */
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(269.84deg, #AD34B7 -40.96%, #8100B4 -4.77%, #AD34B7 163.26%);
}

nav.navbar.navbar-expand-lg.navbar-light{
  position: fixed;
  width: 100%;
  z-index: 99;
}
.header-navbar {
  position: relative;
  transition: all 0.4s ease; /* Smooth transition effect */
  background-color: transparent;
}
/* Dropdown container */
.dropdown-content {
  display: none;
  position: relative;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, height 0.3s ease;
}

/* Active state for the dropdown */
.dropdown-content.active {
  display: block;
  opacity: 1;
  height: auto; /* Expands smoothly */
}

.dropdown-link {
  color: white;
  font-size: 18px;
  text-decoration: none;
  display: block;
  padding: 8px 0;
}

nav.navbar.navbar-expand-lg.navbar-light .mainMenu {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

nav.navbar.navbar-expand-lg.navbar-light .header-content{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
nav.navbar.navbar-expand-lg.navbar-light .mainMenu ul {
  margin: auto;
  padding: inherit;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  color: white;
  padding: 16px 16px;
  /* align-items: center; */
  border-radius: 14px;
  background-color: #181820bf;
  box-shadow: 0px 4px 8px rgb(19 15 19);
  z-index: 1000;
  border-bottom: 2px solid #120f13;
  border-top: 2px solid #120f13;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(2px);
  height: 61px;
  font-size: 21px;
  flex-direction: column;
  width: 240px;
  align-items: flex-start;
  transition: all 0.3s linear;
  /* border: 1px solid #BE1BFF; */
  /* justify-content: center; */
}
nav.navbar.navbar-expand-lg.navbar-light .mainMenu ul.menu-list.active {
  height: 335px;
}
nav.navbar.navbar-expand-lg.navbar-light .mainMenu ul h3 {
  display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      /* margin: auto; */
      font-size: 24px;
}
nav.navbar.navbar-expand-lg.navbar-light .mainMenu ul.menu-list.active a.dropdown-link {
  display: block;
  font-size: 18px;
  text-decoration: auto;
  color: white;
  margin-top: 9px;
}
/* nav.navbar.navbar-expand-lg.navbar-light .mainMenu .toggle-menu {
  width: 182px;
} */
nav.navbar.navbar-expand-lg.navbar-light .header-content button.btn.btn-outline-light {
  display: flex;
  color: white;
  padding: 20px 23px;
  border-radius: 14px;
  background-color: #181820bf;
  box-shadow: 0px 4px 8px rgb(19 15 19);
  z-index: 1000;
  /* border-bottom: 2px solid #120f13; */
  /* border-top: 2px solid #120f13; */
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(2px);
  height: 70px;
  font-size: 21px;
  flex-direction: column;
  /* width: 240px; */
  align-items: flex-start;
  transition: all 0.3s linear;
  /* border-color: #BE1BFF; */
}

nav.navbar.navbar-expand-lg.navbar-light .mainMenu img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 7px 16px;
  height: 70px;
  border-radius: 14px;
  background-color: #181820bf;
  box-shadow: 0px 4px 8px rgb(19 15 19);
  z-index: 1000;
  border-bottom: 2px solid #120f13;
  border-top: 2px solid #120f13;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  object-fit: contain;
  /* border: 1px solid #BE1BFF; */
}


.header-navbar.scrolled {
  position: fixed;
  top: 0;
  width: 100%;
  /* background-color: #130f1363;
  box-shadow: 0px 4px 8px rgb(19 15 19);
  z-index: 1000;
  border-bottom: 2px solid #120f13;
  border-top: 2px solid #120f13;
  backdrop-filter: blur(5px); */
}
.navbar-expand-lg .navbar-nav .nav-link{
  color: white;
}
.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
  width: 100%;
  justify-content: center;
}
/* .bg-sec{
  background: url(./component/assets/banner.mp4);
  background-size: contain;
  height: 80vh;
  display: flex;
  align-items: end;
  justify-content: flex-start;
  padding: 0px 0px 42px;
} */
.video-container {
  position: relative;
  width: 100%;
  height: 800px;
  overflow: hidden;
}

.videoTag {
  width: 100%;
  height: auto;
}
.videoTag1 {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.overlay {    
 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 800px; */
  background-size: contain;
  /* padding-top: 210px; */
}
/* .container.fjffjf{

  height: inherit;
} */
.overlay .second-para{
  color: white;
  text-align: left;
  font-size: 26px;
}
.overlay .second-para button {
  text-align: center;
  justify-content: center;
  padding: 16px 24px;
  width: 44%;
  border-color: #BE1BFF;
  border-width: 7px;
  border-radius: 101px;
  animation: glow 1s infinite !important;
}
.overlay .second-para span {
  color: #BE1BFF;
}
.text-center.toggle {    
  /* position: absolute; */
  margin: auto;
  width: -webkit-fill-available;
  left: 0px;
  transform: translate(0px, 40px);
  display: flex;
  justify-content: center;
}
.text-center.toggle span svg:hover {
  transform: rotate(-90deg);
}
.text-center.toggle span svg {
  font-size: 58px;
  transform: rotate(90deg);
  color: white;
  transition: all 0.3s linear;
}
.bg-sec h2.heading {
  font-size: 35px;
  color: white;
  text-align: left;
  font-family: "Inria Serif", serif;
  margin-bottom: auto;
  font-weight: 700;
  font-style: normal;
  line-height: 4px;
}
.texter {
  /* padding-left: 168px; */
  margin-top: 43px;
}
.bg-sec h4 {
  font-size: 80px;
  color: white;
  text-align: left;
}
.bg-sec button {
  display: flex;
    align-items: center;
    padding: 0px 0px;
    background: none;
    border: 1px solid white;
    color: white;
    justify-content: space-between;
    width: 165px;
    font-family: "Inria Serif", serif;
    font-weight: 800;
    font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.bg-sec .jhvb{
  padding: 8px 15px;
  width: -webkit-fill-available;
  border-right: 1px solid white;
}
.bg-sec button .arrow {    padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sec-two{
  /* background: url(./component/assets/bgsecfrn.png); */
padding: 100px 0px;
background-size: 100%;
}

/* Video Styling */
.video-container1 {
  position: absolute;
  left: 0;
  width: -webkit-fill-available;
  height: 37%;
  z-index: 0;
  margin: 0px 70px;
}

.background-video {
  width: 100%;
  height: -webkit-fill-available;
  object-fit: cover;
  border-radius: 40px;
}

/* Text Overlay Styling */
.text-overlay {
  position: relative;
  display: flex
;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  color: #fff;
  z-index: 1;
  padding: 20px;
}

.text-overlay h2 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.text-overlay p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
}

/* Add Custom Styling */
.text-overlay span {
  color: white; /* Highlighted text color */
}
.sec-two .text-box{
  color: white;
  /* padding: 25px 185px; */
  border-radius: 20px;
  text-align: center;
  display: flex
;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sec-two .text-box h2>span{
  color: #ffffff;
  font-weight: 800;
}
.sec-two .text-box h2{
  font-size: 50px;
  font-family: "Inria Serif", serif;
  color: #fff;
}
.sec-two .text-box p {
  font-size: 24px;
  color: white;
}
.count-box {
  display: flex;
  gap: 20px;
  color: white;
  align-items:center;
}
.count-box span {
  font-size: 110px;
  color: #fff;
}
.count-box p>span {
  color: #fff;
  font-size: larger;
}
.count-box p {
    margin-bottom: inherit;
    font-size: 33px;
    text-align: left;
    line-height: 38px;
    color: #BE1BFF;
}
.sec-two .hearticon {
  display: flex;
  justify-content: end;
  align-items: end;
}
.sec-two .hearticon svg{
  color: #DAB35D;
  background: transparent;
  font-size: 24px;
}
.product-box {
  background: white;
  padding: 18px 11px;
  border-radius: 14px;
  height: 300px;
}
.product-box img {
  object-fit: contain;
  width: 90%;
  /* height: 100%; */
}
.properties {
  text-align: left;
  padding-top: 9px;
}
.properties h3 {
  color: #dab25d;
  font-family: "Inria Serif", serif;
  font-weight: 700;
  font-size: 26px;
  display: flex;
  margin: 0px;
  align-items: center;
  justify-content: space-between;
}
.properties h3 span {
  color: white;
  font-size: 20px;
}
.properties p {
  color: white;
  margin: 0px;
  font-size: 18px;
}
.cart-button {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px 0px;
}
.cart-button button {
  padding: 7px 23px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: #dab25d;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 18px;
}
.third-sec {
  padding: 46px 0px;
}

.third-sec .img-box {
  text-align: center;
  position: relative;
  z-index: 999;
}

.third-sec .img-box .shadow-img {
  height: 300px;
  width: 300px;
  border-radius: 100px;
  margin-bottom: 20px; /* Space below the image */
}

.third-sec .thrd-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  color: white;
}

.shopnow {
  display: flex;
  align-items: center;
}

.shopnow button {
  background-color: #ffcc00; /* Customize as needed */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  margin-right: 20px;
  cursor: pointer;
}

.shopnow h3 {
  font-size: 31px;
  color: white;
  font-family: "LTC Record Title W00 Regular";
  font-weight: 500;
}
.shadow-box {
  box-shadow: 2px -3px 20px 20px rgb(145 139 139 / 10%);
  height: 0px;
  background: #ffffff03;
  border-radius: 109px;
  width: 362px;
  border: 0px solid rgb(145 139 139 / 27%);
  padding: 99px;
  background: rgb(145 139 139 / 10%);
  margin-top: -169px;
  position: relative;
  z-index: 99;
}
.shfhfj{
  display: flex;
  align-items: center;
flex-direction: column;
  justify-content: center;
}
.thrd-text-box h2 {
  font-size: 57px;
  font-family: "LTC Record Title W00 Regular";
}
.thrd-text-box p {
  font-size: 14px;
}
.shopnow button {
  background: #5D4741;
  color: white;
  padding: 10px 25px;
}
.mainHeading span.f-span {
  width: 155px;
  /* box-shadow: -7px 0px 1px 1px gray; */
  border-image-source: linear-gradient(270deg, #ededed 0%, rgb(244 244 244 / 0%) 100%);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  height: 2px;
  background: linear-gradient(329deg, #FFF 0%, rgb(255 255 255 / 0%) 141%);
}
.mainHeading span.s-span {
  width: 155px;
  /* box-shadow: -7px 0px 1px 1px gray; */
  border-image-source: linear-gradient(79deg, #ededed 0%, rgb(244 244 244 / 0%) 100%);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  height: 2px;
  background: linear-gradient(136deg, #FFF 0%, rgb(255 255 255 / 0%) 141%);
}
.mainHeading svg{
  color: white;
  font-size: 28px;
}
.headingsForth p{
  font-size: 18px;
  font-family: "LTC Record Title W00 Regular";
  color: white;
  margin: 0px;
}
.headingsForth h2{
  color: white;
  font-size: 55px;
  font-family: "LTC Record Title W00 Regular";
  margin: 0px;
}
.teals-testimonials-new-sec .swiper-slide img {
  width: 100%;
  height: auto;
  position: relative;
  display: table-footer-group;
  /* border-radius: 10px; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 6px solid #BE1BFF;
  border-radius: 20px;
}
.teals-testimonials-new-sec .swiper{
  overflow: unset;
}
.teals-testimonials-new-sec .swiper-slide.swiper-slide-active:after {
  width: 200px;
  height: 200px;
  content: url('./component/assets/daimond.png');
  position: absolute;
  /* border: 20px solid black !important; */
  bottom: 0%;
    right: -17px;

}
.teals-testimonials-new-sec .swiper-slide h3 {
  color: white;
  font-size: 37px;
  margin-top: 20px;
}
section.forth-sec{
  transform: translate(0px, 125px);
}

.sec-fifth{
  background: url('./component/assets/bg-third.png');
  padding: 31px 0px;
  background-size: contain;
}
.sec-fifth .text-box{
  color: white;
  text-align: center;
}
.sec-fifth .text-box h2>span{
  color: #BE1BFF;
}
.sec-fifth .text-box h2{
  font-size: 50px;
  font-family: "Inria Serif", serif;
  color: #fff;
}
.sec-fifth .text-box p {
  font-size: 18px;
    text-decoration: underline;
    color: #dab25d;
}
.sec-fifth .hearticon {
  display: flex;
  justify-content: end;
  align-items: end;
}
.sec-fifth .hearticon svg{
  color: #DAB35D;
  background: transparent;
  font-size: 24px;
}
/* box1 */
.hoverable .innerbox1 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox1 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox1 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox1 {
  background: url('./component/assets/innerbox1.png');
  height: 300px;
  display: inline-flex;
  justify-content: end;
  padding-bottom: 22px;
  flex-direction: column;
  cursor: pointer;
  width: 410px;
  transition: 0.5s;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
  background-size: cover;
  background-repeat: no-repeat;

}
.hoverable:hover .innerbox1 {
  width: 500px;
}
.modal-content {
  background: #0000008f !important;
  backdrop-filter: blur(10px);
  box-shadow: 0px 2px 4px 1px #BE1BFF;
}
.modal-content .modal-header {
  border: none;
  color: white;
}
.modal-content .modal-body {
  border: none;
  color: white;
}
.modal-content .modal-footer {
  border: none !important;
  justify-content: center;
  transform: translate(0px, 45px);
}
.modal-title.h4 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.modal-title.h4 svg {
  color:#BE1BFF;
  cursor: pointer;
}
button.talk-button.btn.btn-secondary {
  background: #8100B4;
  border: 3px solid #BE1BFF;
  border-radius: 17px;
  display: flex;
    align-items: center;
    gap: 10px;
}
/* box 2 */
.hoverable .innerbox2 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox2 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox2 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox2 {
  background: url('./component/assets/box2.png');
  height: 300px;
    background-size: 100%;
  display: inline-flex;
  justify-content: end;
  cursor: pointer;
  padding-bottom: 22px;
  flex-direction: column;
  width: 410px;
  transition: 0.5s;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
  background-size: cover;
  background-repeat: no-repeat;

}
.hoverable:hover .innerbox2 {
  width: 500px;
}

/* box3 */
.hoverable .innerbox3 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox3 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox3 {
  width: 500px;
}


.hoverable:hover .innerbox3 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox3 {
  background: url('./component/assets/box3.png');
  height: 300px;
    background-size: 100%;
  display: inline-flex;
  justify-content: end;
  padding-bottom: 22px;
  cursor: pointer;
  flex-direction: column;
  transition: 0.5s;
  width: 410px;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
  background-size: cover;
  background-repeat: no-repeat;

}

/* box4 */
.hoverable .innerbox4 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox4 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox4 {
  width: 500px;
}


.hoverable:hover .innerbox4 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox4 {
  background: url('./component/assets/box4.png');
  height: 300px;
    background-size: 100%;
  display: inline-flex;
  justify-content: end;
  padding-bottom: 22px;
  background-size: 100%;
  cursor: pointer;
  flex-direction: column;
  transition: 0.5s;
  width: 410px;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
  background-size: cover;
  background-repeat: no-repeat;

}

/* box5 */
.hoverable .innerbox5 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox5 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox5 {
  width: 500px;
}


.hoverable:hover .innerbox5 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox5 {
  background: url('./component/assets/box5.png');
  height: 300px;
    background-size: 100%;
  display: inline-flex;
  justify-content: end;
  padding-bottom: 22px;
  cursor: pointer;
  flex-direction: column;
  transition: 0.5s;
  width: 410px;
  align-items: center;
  /* border-radius: 21px; */
  padding: 19px 35px;
  background-size: cover;
  background-repeat: no-repeat;

}

/* box6 */
.hoverable .innerbox6 h3 {
  color: white;
  font-size: 30px;
}

.hoverable .innerbox6 p {
  color: white;
  font-size: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hoverable:hover .innerbox6 {
  width: 500px;
}


.hoverable:hover .innerbox6 p {
  opacity: 1;
  transform: translateY(0);
}

.hoverable .innerbox6 {
  background: url('./component/assets/box6.png');
  height: 300px;
    background-size: 100%;
  display: inline-flex;
  justify-content: end;
  padding-bottom: 22px;
  flex-direction: column;
  width: 410px;
  align-items: center;
  transition: 0.5s;
  /* border-radius: 21px; */
  padding: 19px 35px;
  /* transition: opacity 0.5s ease, transform 0.5s ease; */
cursor: pointer;
background-size: cover;
background-repeat: no-repeat;

}

.boxfirst .box-img {
  background: transparent;
  border-radius: 13px;
  border: 1px solid #dab25d;
  width: 45%;
  height: 200px;
}
.boxfirst {
  display: flex;
  margin-top: 66px;
  gap: 30px;
}
.doc-img h2 {
  font-size: 40px;
  color: white;
  font-family: "Inria Serif", serif;
}
.boxfirst .box-img img {
  transform: translate(-26px, 19px);
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.doc-img p {
  color: white;
  font-size: 15px;
}
.doc-img {
  text-align: left;
  width: 55%;
}
.doc-img button {
  padding: 20px 14px;
  border-bottom-left-radius: 32px;
  border-top-right-radius: 32px;
  border: none;
  color: black;
}
.container.footer ul {
  list-style: none;
  padding-left: inherit;
  color: white;
  font-size: 18px;
  text-align: left;
}
.container.footer h3 {
  color: white;
  font-size: 22px;
  text-align: left;
  font-weight: bold;
}
.icons {
  display: flex;
  gap: 10px;
}
.icons svg {
  background: #ffffff24;
  padding: 8px;
  font-size: 42px;
  border-radius: 24px;
  color: #AD34B7;
}
.container.footer {
  padding: 31px 0px;
}
section.footer .d-flex.top-footer button:hover {
  background: #BE1BFF;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
  color: rgb(206 65 211);
}
section#price-plans-section .nav-tabs .nav-link {
  color: white;
}
/* nav.navbar.navbar-expand-lg.navbar-light{
  background: #61028926;
} */
.custom-price-button {
  text-align: center;
}

section#price-plans-section .swiper-button-next {
  padding: 20px;
  background: linear-gradient(94.32deg, #B4008C 0%, #43008C 128.15%) !important;
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

section#price-plans-section .swiper-button-next::after {
  color: white;
  font-size: 24px;
}
section#price-plans-section .swiper-button-prev {
  padding: 20px;
  background: linear-gradient(94.32deg, #B4008C 0%, #43008C 128.15%) !important;
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

section#price-plans-section .swiper-button-prev::after {
  color: white;
  font-size: 24px;
}
@media only screen and (max-width: 768px) {
  .cursor-dot{
    display: none;
  }
  .cursor-follower{
    display: none;
  }
  .hoverable .innerbox1 {
    width: -webkit-fill-available;
}
.hoverable .innerbox2 {
  width: -webkit-fill-available;
}
.hoverable .innerbox3 {
  width: -webkit-fill-available;
}
.hoverable .innerbox4 {
  width: -webkit-fill-available;
}
.hoverable .innerbox5 {
  width: -webkit-fill-available;
}
.hoverable .innerbox6 {
  width: -webkit-fill-available;
}

  .video-container{
    
    height: auto;
  }
  section.overlay.bg-sec{
    position: relative;
    height: auto;
  }
  .video-container video.videoTag{
    display: none;
  }
  section.overlay.bg-sec .container.fjffjf {
    padding-top: 60px;
}
  nav.navbar.navbar-expand-lg.navbar-light .mainMenu ul h3{
    justify-content: flex-end !important;
  }
  nav.navbar.navbar-expand-lg.navbar-light .mainMenu ul{
    padding: 18px 35px !important;
    text-align: right !important;
    align-items: baseline !important;
  }
  .containerCursor {
    display: none;
  }
  nav.navbar.navbar-expand-lg.navbar-light .mainMenu ul h3 span {
    display: none;
}
nav.navbar.navbar-expand-lg.navbar-light .mainMenu{
  justify-content: space-between;
  width: 100%;
}
nav.navbar.navbar-expand-lg.navbar-light .mainMenu ul{
  width: auto;
}
nav.navbar.navbar-expand-lg.navbar-light .header-content button.btn.btn-outline-light{
  display: none;
}
  .contact-form-container{
    padding: 14px;
  }
  .fixed-buttonsArrow{
    padding: 0 0px !important;
  }
  .fixed-buttons1{
    display: none !important;
  }
  .texter{
    padding-left: inherit;
  }
  .overlay .second-para button{
    width: fit-content;
    padding: 6px 16px;
    font-size: 17px;
  }
  .text-center.toggle{
    margin-top: 34px;
    transform: translate(0px, 0px);
  }
  .sec-fifth .text-box h2{
    font-size: 34px;
  }
  button.talk-button.btn.btn-secondary{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .testimonial-title h3{
    font-size: 20px;
  }
  section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .testimonial-title h2{
    font-size: 36px !important;
  }
  .heading-text h3{
    font-size: 36px !important;
  }
  section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow ul#myTab{
    margin-top: 15px;
  }

  section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .nav-tabs .nav-link.active{
    padding: 9px 23px !important;
    line-height: normal;
  }
  section#price-plans-section .nav-tabs .nav-link{
    line-height: normal;
    padding: 9px 23px !important;
  }
  .bg-sec h4{
    font-size: 100px;
    line-height: 118px;
  }
  section.sec-two#second .row {
    justify-content: center;
}
.hoverable .innerbox1 p{
  opacity: 1;
  font-size: 16px;
}
.hoverable .innerbox2 p{
  opacity: 1;
  font-size: 16px;
}
.hoverable .innerbox3 p{
  opacity: 1;
  font-size: 16px;
}
.hoverable .innerbox4 p{
  opacity: 1;
  font-size: 16px;
}
.hoverable .innerbox5 p{
  opacity: 1;
  font-size: 16px;
}
.hoverable .innerbox6 p{
  opacity: 1;
  font-size: 16px;
}
.count-box{
  display: flex;
  gap: 0px;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.count-box p{
  text-align: center;
  font-size: 18px;
  line-height: normal;
}
.count-box span{
  font-size: 48px;
}
.sec-fifth .col-lg-12.d-flex.gap-4.mt-4 {
  flex-wrap: wrap;
}
.teals-testimonials .carousel{
  height: fit-content !important;
}
.teals-testimonials-new-sec .swiper-slide.swiper-slide-active:after{
  display: none;
}
.hoverable:hover .innerbox1 {
  width: auto;
}

.hoverable:hover .innerbox2 {
  width: auto;
}

.hoverable:hover .innerbox3 {
  width: auto;
}

.hoverable:hover .innerbox4 {
  width: auto;
}

.hoverable:hover .innerbox5 {
  width: auto;
}
.hoverable:hover .innerbox6 {
  width: auto;
}
.testimonial-title p{
  font-size: 16px !important;
  padding: 0px 23px !important;
  margin-bottom: 21px !important;
}
.second-para p {
  font-size: 16px;
}
section#price-plans-section .nav-tabs .nav-link {
  color: white;
}
.testimonial-title h2{
  font-size: 36px !important;
}
section.footer .d-flex.top-footer{
  flex-direction: column;
}
section.footer .d-flex.top-footer .startup-width{
  width: 100% !important;
}
section.footer .d-flex.top-footer h2 {
  font-size: 28px !important;
        text-align: center !important;
        line-height: normal !important;
}
section.footer .d-flex.top-footer h2 br{
  display: none;
}
section.footer .col-lg-12.boxRadions .box-SociallinksSecond{
  padding: 0px 0px !important;
}

section.footer .col-lg-12.boxRadions.marquee {
  display: none;
}
section#price-plans-section{
  padding: 10px;
}
.about-section .container .col-lg-12{
  height: 25vh !important;
  background-size: cover !important;
}
section.about-section .container h2.section-title{
  font-size: 28px !important;
}
.container.contentsec h2{
  font-size: 26px !important;
  flex-direction: column;
}
.container.contentsec{
  padding: 30px 15px !important;
}
.container.contentsec p {
  font-size: 16px !important;
  letter-spacing: 1px !important;
}
section.brands-we-have-worked h2.section-title{
  font-size: 26px !important;
}
section.meet-our-team .container .textHead{
  flex-direction: column;
}
section.meet-our-team p{
  margin: 0  !important;
}
section.about-section {
  padding: 0px 10px;
}
section.faq-section .faq-wrapper::before{
  right: 0px !important;
  margin-top: -45px !important;
}
section.meet-our-team h2 {
  font-size: 26px !important;
}
section.footer .d-flex.top-footer button{
  padding: 9px 42px;
}
.container.footer.main .d-flex.gap-3 {
  flex-direction: column;
  margin-bottom: 28px;
  justify-content: center;
  align-items: center;
}
.container.footer.main img{
  width: 100%;
}
.container.footer ul{
  margin-bottom: 34px;
}
  .navbar {
      --bs-navbar-toggler-icon-bg: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23FFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E) !important;
      --bs-navbar-toggler-border-color: rgb(255 255 255) !important;
  }
  .bg-sec h2.heading{
    margin-top: 36px;
    font-size: 24px;
  }
  .navbar-collapse.collapse.show {
    background: #504d4dcc;
    border-radius: 20px;
}
.navbar-expand-lg .navbar-nav .nav-link{
  color: #ffffff;
}
.sec-two .text-box {
  padding: 10px 18px;
  text-align: left;
}
.product-box img{
  width: 55%;
}
.thrd-text-box h2{
  font-size: 30px;
}
section.forth-sec{
  transform: translate(0px, 0px);
}
.properties{
  margin-bottom: 20px;
}
.sec-fifth{

  padding: 46px 0px 46px;
}
.boxfirst .box-img img{

  transform: translate(-8px, -26px);
}
.boxfirst{
  margin-top: 41px;
}
.doc-img h2{
  font-size: 22px;
}
.doc-img p{
  font-size: 13px;
}
.doc-img button{
  padding: 10px 17px;
}
.container.footer {
  padding: 31px 20px;
}
.sec-two .text-box h2{
  font-size: 36px;
}
.sec-two .text-box p{
  font-size: 16px;
}
.product-box{
  height: 265px;
}
.third-sec .img-box .shadow-img{
  height: 200px;
    width: 200px;
}
.shadow-box{
  margin-top: -200px;
}
.headingsForth h2{
  font-size: 45px;
}
.headingsForth p{
  font-size: 13px;
}
section.faq-section .faq-wrapper .faq-item .question h4{
  font-size: 18px !important;
}
.hoverable .innerbox1 h3 {
  font-size: 28px;
}
.hoverable .innerbox2 h3{
  font-size: 28px;
}
.hoverable .innerbox3 h3{
  font-size: 28px;
}
.hoverable .innerbox4 h3{
  font-size: 28px;
}
.hoverable .innerbox5 h3{
  font-size: 28px;
}
.hoverable .innerbox6 h3{
  font-size: 28px;
}

section.contact-us .container.main{
  width: auto !important;
  /* height: 50vh !important; */
}
section.contact-us .container.main .section-title h2{
  font-size: 38px !important;
}
section.contact-us .container .contactForm .col-lg-12 {
  justify-content: center;
  display: flex;
}

section.contact-us .container .contactForm .col-lg-12 .row {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

section.contact-us .container .contactForm .col-lg-12 .row .col-lg-8 {
  justify-content: center;
  display: flex;
}
.hero .mainHeading h2 {
  font-size: 38px !important;
}

.hero .mainHeading {
  /* height: 40vh !important; */
  width: auto !important;
  justify-content: end;
}
.hero .mainHeading p{
  font-size: 16px !important;
}
section.hero .container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.faq-item .answer p{
  padding: 0px 7px !important;
}
.services-box h2 {
  font-size: 28px !important;
}
.services-box p {
  font-size: 16px !important;
}
section.stage-process .container .vertical-timeline-element-title{
  font-size: 28px !important;
}
.contact-form-container.open .contact-form {
  width: 100% !important;
}
}
.contact-form-container.open .contact-form {
  width: 455px;
}
section.footer{
  padding: 31px 0px 0px 0px;
  background-image: url('./component/assets/testimonials.png');
  background-size: 100% 100%;
  background-position: right;
  background: #130f13;
  position: relative;
  /* margin-top: 300px; */
}

/* testimonial */
section.footer .d-flex.top-footer {
  justify-content: space-between;
  color: white;
  align-items: center;
}

section.footer .d-flex.top-footer h2 {
  font-size: 140px;
  text-align: left;
  line-height: 92px;
}
section.footer .d-flex.top-footer h2 span {
  color: #808080c9;
}

section.footer form.footerForm {
  margin: auto;
  display: flex;
  color: white;
  padding: 20px 16px;
  border-radius: 20px;
  background-color: #181820bf;
  box-shadow: 0px 4px 8px rgb(19 15 19);
  z-index: 1000;
  border-bottom: 2px solid #120f13;
  border-top: 2px solid #120f13;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(2px);
  font-size: 21px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  transition: all 0.3s linear;
}

section.footer form.footerForm fieldset input::placeholder, section.footer form.footerForm fieldset textarea::placeholder {
  color: rgb(255, 255, 255);
}
section.footer form.footerForm fieldset input, section.footer form.footerForm fieldset textarea {
  appearance: none;
  display: inline-block;
  width: 100%;
  line-height: 1.4em;
  outline: none;
  border: none;
  padding: 15px;
  border-radius: 20px;
  font-family: "unbounded Regular", unbounded, Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.3);
  color: rgb(255, 255, 255);
  box-shadow: transparent 0px 0px 0px 1px inset;
}

section.footer form.footerForm fieldset {
  width: 100%;
}

section.footer .col-lg-12.boxRadions {
  margin: auto;
  display: flex;
  color: white;
  padding: 0px 16px;
  border-radius: 20px;
  background-color: #181820bf;
  box-shadow: 0px 4px 8px rgb(19 15 19);
  z-index: 1000;
  border-bottom: 2px solid #120f13;
  border-top: 2px solid #120f13;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(2px);
  font-size: 21px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  transition: all 0.3s linear;
  margin-top: 20px;
}

section.footer .col-lg-12.boxRadions .box-Sociallinks {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  opacity: 1;
  display: flex;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  overflow: hidden;
  padding: 20px 30px;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0px;
  font-size: 18px;
  text-align: left;
}
section.footer .col-lg-12.boxRadions .box-Sociallinks svg {
  fill: #b76ad5de;
  font-size: larger;
}

section.footer .col-lg-12.boxRadions .box-SociallinksSecond {
  align-content: flex-start;
  align-items: flex-start;
  display: flex
;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  height: min-content;
  justify-content: space-between;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
  padding: 0px 50px;
}

section.footer .col-lg-12.boxRadions .box-SociallinksSecond ul {
  list-style: none;
  padding: inherit;
  flex-direction: column;
  width: max-content;
  text-align: left;
  font-size: 18px;
}
section.footer .col-lg-12.boxRadions .box-SociallinksSecond ul li {
  margin-top: 7px;
  color: #d7d7d7;
}
section.footer .col-lg-12.boxRadions .box-SociallinksSecond ul li.heading {
  color: #b76ad5de;
}
section.footer .col-lg-12.boxRadions .box-SociallinksSecond ul li a {
  color: #d7d7d7;
}
section.footer .col-lg-12.boxRadions .iconsSet {
  display: flex;
}

section.footer .col-lg-12.boxRadions .iconsSet .Sociallinks {
  margin: 10px;
  border: 2px solid #c000fe;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  color: #d7d7d7;
  font-size: 28px;
}
section.footer .col-lg-12.boxRadions .iconsSet .Sociallinks a{
  color: white;
}
section.contact-us .col-lg-12 .boxRadions .iconsSet .Sociallinks {
  margin: 10px;
  border: 2px solid #c000fe;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  color: #d7d7d7;
  font-size: 28px;
}
section.contact-us .col-lg-12 .boxRadions .iconsSet .Sociallinks a{
  color: white;
}
section.footer .col-lg-12.boxRadions p {
  color: #BE1BFF;
  text-shadow: 1px -2px white;
}
.carousel-cell.logos {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  opacity: 1;
  display: flex;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  overflow: hidden;
  padding: 20px 30px;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0px;
  font-size: 18px;
  text-align: left;
  width: 100%;
}
.rfm-marquee, .rfm-initial-child-container {justify-content: space-between;width: inherit;padding-left: 29px;}
/* cell number */
section.footer .d-flex.top-footer button {
  text-align: center;
  justify-content: center;
  padding: 13px 40px;
  border-color: #BE1BFF;
  border-width: 7px;
  border-radius: 101px;
  background: transparent;
  color: white;
  font-size: 23px;
  font-weight: 700;
  animation: glow 1s infinite ;

}
.container.footer.main img {
  object-fit: contain;
  width: 83%;
}
.container.footer.main li {
  margin-top: 12px;
}

  .gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
}
.gallery-item:hover {
    transform: scale(0.9);
    transition: .9s;
}

.gallery-item {
    flex-basis: 32.7%;
    margin-bottom: 6px;
    opacity: 1 !important;
    cursor: pointer;
}
 .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fixed-buttons {
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: fixed;
  right: -70px;
  z-index: 999;
}

.fixed-buttons:hover {
  right: -69px;
  transform: scale(0.9);
  transition: .4s;
}

.fixed-buttons button {
  background: transparent;
  border: 2px solid gray;
  text-align: center;
  justify-content: center;
  padding: 9px 32px;
  border-color: #BE1BFF;
  font-size: 20px;
  border-width: 5px;
  border-top-left-radius: 28px;
  color: white;
  border-top-right-radius: 28px;
  font-weight: 600;
  transform: scale(0.9) rotate(-90deg);
  transition: .9s;
}
.contact-form-container .fixed-buttons1 {
  display: none;
}
.contact-form-container.open .fixed-buttons1 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  z-index: 999;
  transform: rotate(-90deg) translate(0px, -290px);
  position: absolute;
}
.fixed-buttons1:hover {
  /* right: -69px; */
  transform: scale(0.9) rotate(-90%);
  transition: .4s;
}

.fixed-buttons1 button {
  transform: rotate(-90deg);
    background: transparent;
    border: 2px solid gray;
    text-align: center;
    justify-content: center;
    padding: 9px 32px;
    border-color: #BE1BFF;
    font-size: 20px;
    border-width: 5px 5px 0px;
    border-top-left-radius: 28px;
    color: white;
    border-top-right-radius: 28px;
    font-weight: 600;
    transform: scale(0.9);
    transition: .9s;
}
.fixed-buttonsArrow {
  display: flex;
  flex-direction: column;
  gap: 25px;
  z-index: 999;
  transform: rotate(0deg) translate(0px, 0px);
  padding: 0px 16px;
  position: relative;
}
.fixed-buttonsArrow svg {
  color: #BE1BFF;
  font-size: -webkit-xxx-large;
  cursor: pointer;
}
.fixed-buttonsArrow:hover {
  /* right: -69px; */
  transform: scale(0.9) rotate(-90%);
  transition: .4s;
}


.contact-form-container {
  position: fixed;
  top: 0;
  right: -400px; /* Initially hidden off-screen */
  width: 400px;
 
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  transition: right 0.4s ease;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contact-form-container.open {
  right: 0; /* Slide into view */
  width: 100%;
}

.contact-form {
  padding: 20px;
  color: white;
  border-radius: 17px;
  border: 6px solid #BE1BFF;
  background: #00000045;
  backdrop-filter: blur(2px);
}

.contact-form h2 {
  color: #fff;
  margin-bottom: 20px;
}

.contact-form input, 
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: transparent;
  border: 2px solid gray;
  color: white;
}

.contact-form button {
  width: 100%;
  padding: 12px;
  background-color: #BE1BFF;
  border: none;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; 
    justify-content: center;
    text-align: center;
}

.contact-form button:hover {
  background-color: #8100b4;
}

.arrowup-static, .phone-static {
  background: #fff;
  border-radius: 53px;
  color: #ff6500;
  font-size: 31px;
  height: 40px;
  margin-left: 2px;
  padding: 12px;
  width: 40px;
}
@keyframes glow {
  0% {
     box-shadow: 5px 5px 20px #BE1BFF, -5px -5px 20px #BE1BFF;
 }
 50% {
     box-shadow: 5px 5px 20px #be1bff2b, -5px -5px 20px #be1bff2b;
 }
 
 100% {
     box-shadow: 5px 5px 20px #BE1BFF, -5px -5px 20px #BE1BFF;
 }
 }
 
@keyframes updown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
section.overlay.bg-sec .contact-form:before {
  position: absolute;
  content: '';
  display: block;
  height: 139px;
  width: 139px;
  top: -63px;
  right: -62px;
  animation: updown 2s ease-in-out infinite;
  background:  url('./component/assets/daimond.png');
  background-repeat: round;

}

/* App.css */

.cursor-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background-color: rgba(255, 83, 104, 0.1);
  border: 2px solid #FF5368;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FF5368;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: transform 0.1s ease;
  box-shadow: 0 0 10px rgba(255, 83, 104, 0.5), 0 0 20px rgba(255, 83, 104, 0.3);
}


.cursor-hover.show {
  opacity: 1;
  transform: scale(1.2);
}

nav.navbar.navbar-expand-lg.navbar-light .mainMenu ul{
  text-align: left;
}

.services-container {
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
  background: url(./component/assets/bgMain.png);
  background-size: 100%;
  background-attachment: fixed;
  /* filter: hue-rotate(93deg); */
  padding: 20px 0px;
  transition: background-position 0.1s 
}
.container.contentsec img {
  object-fit: contain;
  width: 100%;
  border-radius: 100px;
}

.container.contentsec h2 {
  text-align: left;
  margin: 30px 0px;
  display: flex;
  font-size: 38px;
  align-items: center;
  gap: 11px;
  color: white;
  flex-wrap: wrap;
}

.container.contentsec h2 span {
  font-size: 16px;
  padding: 10px 25px;
  background: white;
  color: black;
  border-radius: 100px;
}
.container.contentsec p {
  text-align: left;
  color: #ffffffab;
  font-size: 18px;
}
.container.contentsec p.sub-answer {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}

.container.contentsec p.sub-answer span {
  color: #c5008a;
  font-weight: 700;
}
.container.contentsec p.sub-answer svg {
  font-size: 30px;
    color: white;
    animation: glow 1s infinite !important;
    background: #b2008c;
    border-radius: 100px;
}
.services-container .services-container {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
.container.contentsec ul.sub-list {
  list-style: unset;
  text-align: left;
  /* padding-left: 0px; */
  color: white;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
}

.container.contentsec ul.sub-list li {padding-left: 26px;list-style: disc;padding: 12px 0px;}

.container.contentsec h3 {
  color: white;
  text-align: left;
  font-size: 22px;
  margin: 12px 0px;
}


  .hero .mainHeading h2{
    color: white;
    font-size: 54px;
  }
  .hero .mainHeading{
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    gap: 10px;
  }
  section.hero.mainLeadservices .mainHeading {
    height: 70vh;
}
  .hero .mainHeading p {
    color: #d7d7d7;
}
.services-box {
  margin: 10px 0px;
  display: flex;
  color: white;
  padding: 25px 25px;
  border-radius: 20px;
  background-color: #181820bf;
  z-index: 1;
  border-bottom: 2px solid #120f13;
  border-top: 2px solid #120f13;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(2px);
  font-size: 21px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  transition: transform 0.3s ease, z-index 0.3s ease;
  text-align: left;
  height: 305px;
  border: 2px solid rgb(28 36 76 / 1);
  box-shadow: inset 0 -10px 40px #ad34b761;
}

.services-box:hover {
  transform: scale(1); 
  z-index: 2;
  box-shadow: 0px 0px 8px #ad34b761;
  border-color: rgb(28 36 76 / 1);
}

.services-box:not(:hover) {
  transform: scale(0.95); /* Scales down the non-hovered boxes */
}

.services-box .icon {
  padding: 10px;
  border: 1px solid gray;
  border-radius: 14px;
  width: fit-content;
}

.services-box svg {
  font-size: 36px;
}

.services-box h2 {
  font-size: 36px;
}

.services-box button.btn {
  border: none;
  color: white;
  display: flex;
  gap: 12px;
  align-items: center;
}

.services-box button.btn span {
  border: 1px solid gray;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  rotate: -39deg;
  transition: all 0.3s linear;
}

.services-box:hover button.btn span {
  rotate: 0deg;
}

section.hero {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tintBg{
  display: flex;
    color: white;
    padding: 20px 23px;
    border-radius: 14px;
    background-color: #18182012;
    box-shadow: inset -2px 0px 13px 0px #8100b4;
    z-index: 1000;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(2px);
    /* height: 70px; */
    font-size: 21px;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s linear;
    justify-content: center;
}

section.stage-process .container h2.section-title {
  text-align: center;
  margin: 30px 0px;
  display: flex;
  font-size: 38px;
  align-items: center;
  gap: 11px;
  color: white;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

section.stage-process .container h2.section-title span {
  font-size: 16px;
  padding: 10px 25px;
  background: white;
  color: black;
  border-radius: 100px;
}
section.stage-process .container .vertical-timeline-element-title{
  font-size: 40px;
}
section.stage-process .container span.vertical-timeline-element-icon.bounce-in{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-shadow: 1px 0px 6px #6b008c;
  font-weight: 800;
  font-size: 30px;
}
.fade-in {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
  transform: translateX(50px);
}


.price-plans-section .custom-price-button ul li {
  display: inline-block;
}
.price-plans-section .fade:not(.show) {
  display: none !important;
}
.price-plans-section .custom-price-button ul {
  margin: 0;
  padding: 0;
  text-align: center;
}
.price-plans-section .nav-tabs .nav-link {
  border: 0 !important;
  letter-spacing: 0;
  min-width: auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-align: center;
  text-transform: capitalize;
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 54px;
  color: #4D4D4D;
}
.price-plans-section ul#myTab {
  padding: 0 0 !important;
  border: 1px solid #4E4E4E;
  border-radius: 18px;
  text-align: center;
  width: 74%;
  margin: 0 auto;
}
.heading-text h3 {
  font-size: 50px;
  font-family: "Inria Serif", serif;
  color: #fff;
  margin-bottom: 23px;
}


.price-plans-section .price-heading-text {
  border: 2px solid rgb(28 36 76 / 1);
  box-shadow: inset 5px 11px 9px rgba(0, 0, 0, 0.18);
  transition: .9s;
  padding: 17px 20px;
  border-radius: 10px;
  margin-bottom: 13px;
}

.price-plans-section .price-heading-text h2 {
  font-family: 'Anek Tamil';
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  margin: 20px 0;
}

.price-plans-section .price-heading-text h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 30px;
  text-align: left;
  color: #FFFFFF;
}

.price-plans-section .price-list ul li::before {
  color: #5da555 !important;
  content: '✔';
  font-size: 16px;
  line-height: 33px;
  position: relative;
  padding-right: 8px;
}
.d-lg-block {
  display: block !important;
}
.price-plans-section .price-list ul {
  margin: 0;
  padding-left: 0px;
  height: 246px;
  overflow-y: auto;
}
.price-plans-section .price-list {
  /* background: #252525;
  border: 1px solid #2F2F2F;
  border-radius: 15px;
  padding: 32px 0px 32px 13px;
  margin: 11px 0 0px 0; */
}
.price-plans-section .price-list ul li {
  /* font-family: 'Anek Tamil'; */
  font-style: normal;
  font-weight: 400;
  font-size: .875rem;
  line-height: 26px;
  color: rgb(158 163 191 / 1);
  list-style: none;
  padding-left: 6px;
  text-align: left;
}
.price-plans-section .price-inner-plan {
  border: 2px solid rgb(28 36 76 / 1);
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.13);
  border-radius: 2rem;
  padding: 40px 40px;
  --tw-shadow-colored: inset 0 -10px 40px rgb(26 34 72 / .5);
  --tw-shadow: inset 0 -10px 40px rgb(26 34 72 / .5);
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .5s;
  background: rgb(5 7 26 / 1);
  text-align: left;
  box-shadow: inset 0 -10px 40px #ad34b761;
}
.price-plans-section .price-inner-plan h3 {
  font-weight: 300;
  font-size: .875rem;
  color: rgb(158 163 191 / 1);
}
.price-plans-section .price-inner-plan h2.heading-pricing-colors {
  font-size: 1.125rem;
  color: white;
}
.price-plans-section .price-heading-text p.text-sm.font-light {
  color: rgb(158 163 191 / 1);
  font-size: .875rem;
  margin: auto;
}
.price-plans-section .nav-tabs .nav-link:hover {
  background: #ff10 !important;
}
.price-plans-section .tab-pane {
  border: 0 !important;
  margin-top: 50px;
  background: none;
}
.price-plans-section li.nav-item {
  text-align: center;
  margin: 0 auto !important;
  padding: 0 !important;
}

.price-plans-section .nav-tabs .nav-link.active {
  background: #ff10 !important;
  color: #AD34B7 !important;
}
.price-plans-section .price-inner-plan:hover {
  border-color: rgb(28 36 76 / 1);
  transition: .9s;
  transform: translateY(30px);
  box-shadow: 0px 0px 8px #ad34b761;
}

.price-plans-section .price-inner-plan:hover .price-heading-text {
  border: 2px solid #AD34B7;
  box-shadow: inset 5px 11px 9px rgba(0, 0, 0, 0.18);
  transition: .9s;
  padding: 17px 20px;
  border-radius: 10px;
  margin-bottom: 13px;
}
.custom-price-button button {
  background: transparent;
  color: white;
  text-align: center;
  justify-content: center;
  padding: 12px 46px;
  border-color: #8100b4;
  border-width: 5px;
  border-radius: 17px;
  animation: glow 1s infinite !important;
}
.about-section .container .col-lg-12 {
  background: linear-gradient(45deg, black, transparent), url(./component/assets/Webcover.png);
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 26px;
  height: 45vh;
  display: flex;
  align-items: end;
  justify-content: center;
  background-position-y: center;
}
.about-section .container {
  padding: 120px 0px 0px 0px;
}
section.about-section .container h2.section-title {
  color: white;
  font-size: 44px;
}
.container.contentsec {
  padding: 30px 0px;
}
section.brands-we-have-worked h2.section-title {
  text-align: center;
  margin: 30px 0px;
  display: flex;
  font-size: 38px;
  align-items: center;
  gap: 11px;
  color: white;
  justify-content: center;
  flex-direction: column;
}

section.brands-we-have-worked h2.section-title span {
  font-size: 16px;
  padding: 10px 25px;
  background: white;
  color: black;
  border-radius: 100px;
}

section.brands-we-have-worked .carousel-cell.logos img {
  width: 76%;
}
section.brands-we-have-worked .container .brand-list {
  border: 1px solid #000000;
  border-radius: 33px;
  box-shadow: 0px 0px 9px 0px #8100b4;
  margin: 10px;
}

section.brands-we-have-worked .carousel-cell.logos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px;
}

section.brands-we-have-worked .rfm-marquee {
  gap: 20px;
}

section.brands-we-have-worked .rfm-initial-child-container {
  gap: 20px;
}
section.meet-our-team h2 {
  text-align: left;
  margin: 30px 0px;
  display: flex;
  font-size: 38px;
  align-items: center;
  gap: 11px;
  color: white;
  width: 100%;
}

section.meet-our-team h2 span {
  font-size: 16px;
  padding: 10px 25px;
  background: white;
  color: black;
  border-radius: 100px;
}

section.meet-our-team p {
  text-align: left;
  color: #ffffffab;
  font-size: 20px;
  width: 90%;
  margin: 48px 0px 0px 0px;
}

section.meet-our-team p span {
  color: white;
  font-weight: 700;
}


section.meet-our-team .temaMember:before {
  content: '';
  position: absolute;
  width: 400px;
  height: 518px;
  background: #c000fe;
  z-index: 0;
  border-radius: 171px;
}

section.meet-our-team .temaMember img {
  position: relative;
  object-fit: contain;
  width: 75%;
}

section.meet-our-team .temaMember {
  display: flex;
  align-items: end;
  justify-content: center;
}

section.meet-our-team .temaMember:after {
  content: 'BOBANLEKOSKI';
  position: absolute;
  /* width: 300px; */
  z-index: 999999;
  border-radius: 171px;
  color: #67008b;
  font-size: 63px;
  text-align: left;
  text-shadow: 2px 13px 1px #67008b52;
}
section.meet-our-team .container .textHead {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
section.contact-us .container.main .section-title h2 {
  color: white;
  font-size: 54px;
}
section.contact-us .container.main {
  height: 40vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  gap: 10px;
}
section.contact-us .container .contactForm {
  margin: auto;
  display: flex;
  color: white;
  padding: 20px 16px;
  border-radius: 20px;
  background-color: #181820bf;
  box-shadow: 0px 4px 8px rgb(19 15 19);
  z-index: 1000;
  border-bottom: 2px solid #120f13;
  border-top: 2px solid #120f13;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(2px);
  font-size: 21px;
  align-items: flex-start;
  gap: 10px;
  transition: all 0.3s linear;
  margin-top: 20px;
  justify-content: space-between;
}

section.contact-us .container .contactForm .contactBox {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  opacity: 1;
  display: flex;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  overflow: hidden;
  padding: 20px 30px;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0px;
  height: 200px;
  font-size: 18px;
  text-align: left;
}
section.contact-us .col-lg-12 .boxRadions .iconsSet {
  display: flex;
  justify-content: center;
}
section.contact-us .container .contactForm .contactBox h3 {
  font-size: 28px;
}

section.contact-us .container .contactForm .contactBox p {
  font-size: 20px;
}
.teals-testimonials.teals-testimonials-new-sec .gallery img {
  display: block;
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.teals-testimonials.teals-testimonials-new-sec .gallery img:hover {
  transform: scale(1); /* Slight zoom effect on hover */
}

.custom-cursor,
.cursor-follower {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  border-radius: 50%;
  z-index: 9999;
}

/* .custom-cursor {
  width: 20px;
  height: 20px;
  background-color: #67008b;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}

.cursor-follower {
  width: 20px;
  height: 20px;
  background-color: #67008b;
  transform: translate(-50%, -50%);
  opacity: 0.5;
} */
.text-box h2 .letter, .text-box p .letter {
  display: inline-block;
  position: relative;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
    padding: 60px 28px;
    border: 2px solid #BE1BFF;
    border-radius: 10px;
    text-align: center;
    max-width: 90%;
    max-height: 80%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    background-color: #00000040;
    box-shadow: 0px 4px 8px rgb(19 15 19);
    z-index: 1000;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(6px);
    object-fit: contain;
    height: 100%;
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.popup-image {
  width: 100%;
    margin-bottom: 20px;
    height: 100%;
    object-fit: contain;
}
.popup-overlay .popup-content .popup-controls {
  display: flex;
  gap: 11px;
}
.popup-controls button {
  background: #BE1BFF;
    color: #fff;
    border: none;
    margin: 0 5px;
    border-radius: 51px;
    cursor: pointer;
    font-size: 1rem;
    height: 50px;
    width: 50px;
    display: flex;
    transition: all 0.3s linear;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popup-controls button:hover {
  background: #fff;
  color: #BE1BFF;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: #fff;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
}

section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .row.gallery .col-sm-12.col-lg-6.mt-5.col-12.galleryimages .image-container {
  height: 300px;
}
section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .row.gallery .col-sm-12.col-lg-6.mt-5.col-12.mainimage .image-container {
  height: 630px;
}

section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .row.gallery .col-sm-12.col-lg-6.mt-5.col-12.galleryimages .image-container img {
  object-fit: cover;
  height: 100% !important;
  object-position: top;
}
section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .row.gallery .col-sm-12.col-lg-6.mt-5.col-12.mainimage .image-container img {
  object-fit: cover;
  height: 100% !important;
  object-position: top;
}
section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .row.gallery .col-sm-12.col-lg-6.mt-5.col-12.mainimage .image-container {
  overflow: hidden;
  position: relative;
}
section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .row.gallery .col-sm-12.col-lg-6.mt-5.col-12.galleryimages .image-container {
  overflow: hidden;
  position: relative;
}

section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .row.gallery .col-sm-12.col-lg-6.mt-5.col-12.mainimage .image-container img {
  transition: transform 2.5s ease, object-position 2.5s ease !important;
  /* transform: scale(1); */
}

section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .row.gallery .col-sm-12.col-lg-6.mt-5.col-12.mainimage .image-container:hover img {
  /* transform: scale(1.2); */
  object-position: bottom;
}
section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .row.gallery .col-sm-12.col-lg-6.mt-5.col-12.galleryimages .image-container img {
  transition: transform 3.5s ease, object-position 3.5s ease !important;
  /* transform: scale(1); */
}

section.teals-testimonials.teals-testimonials-new-sec#price-plans-section .container.shadow .row.gallery .col-sm-12.col-lg-6.mt-5.col-12.galleryimages .image-container:hover img {
  /* transform: scale(1.2); */
  object-position: bottom;
}