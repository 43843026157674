/* Scroll to top button */
.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Scroll progress bar */
  .scroll-progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 5px;
    background: linear-gradient(87deg, #BE1BFF, #8700bd);
    transition: width 0.2s ease;
  }
  
  /* Scroll button */
  .scroll-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #99008c;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  
  .scroll-button:hover {
    opacity: 1;
  }
  
  .scroll-button:active {
    transform: scale(0.95);
  }
  