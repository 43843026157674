section#price-plans-section{
    position: relative;
    overflow: visible;
    background-size: 100% 100%;
    padding: 30px 0px;
    /* padding-bottom: 70px; */
    /* background-image: url(../assets/testimonials.png); */
    background-size: contain;
    background-position: right;
}
section#price-plans-section .custom-price-button ul li {
    display: inline-block;
}
section#price-plans-section .fade:not(.show) {
    display: none !important;
}
section#price-plans-section .custom-price-button ul {
    margin: 0;
    padding: 0;
    text-align: center;
}
section#price-plans-section .nav-tabs .nav-link {
    border: 0 !important;
    letter-spacing: 0;
    min-width: auto !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    text-align: center;
    text-transform: capitalize;
    /* font-family: 'Anek Tamil'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 46px;
    color: #4D4D4D;
}
section#price-plans-section .container.shadow .nav-tabs .nav-link {
    letter-spacing: 0;
    min-width: auto !important;
    text-align: center;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 46px;
    color: #4D4D4D;
    color: white !important;
    border-radius: 14px;
    border: 1px solid white !important;
    padding: 2px 23px !important;
    margin: 10px 0px;
    background-color: #181820bf;
    box-shadow: 0px 4px 8px rgb(19 15 19);
    backdrop-filter: blur(2px);
}
section#price-plans-section ul#myTab {
    padding: 0 0 !important;
    border: 1px solid #4E4E4E;
    border-radius: 18px;
    text-align: center;
    width: 74%;
    margin: 0 auto;
}
.heading-text h3 {
    font-size: 50px;
    font-family: "Inria Serif", serif;
    color: #fff;
    margin-bottom: 23px;
}


section#price-plans-section .price-heading-text {
    border: 2px solid rgb(28 36 76 / 1);
    box-shadow: inset 5px 11px 9px rgba(0, 0, 0, 0.18);
    transition: .9s;
    padding: 17px 20px;
    border-radius: 10px;
    margin-bottom: 13px;
}

section#price-plans-section .price-heading-text h2 {
    /* font-family: 'Anek Tamil'; */
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin: 20px 0;
}

section#price-plans-section .price-heading-text h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 30px;
    text-align: left;
    color: #FFFFFF;
}

section#price-plans-section .price-list ul li::before {
    color: #5da555 !important;
    content: '✔';
    font-size: 16px;
    line-height: 33px;
    position: relative;
    padding-right: 8px;
}
.d-lg-block {
    display: block !important;
}
section#price-plans-section .price-list ul {
    margin: 0;
    padding-left: 0px;
    height: 246px;
    overflow-y: auto;
}
section#price-plans-section .price-list {
    /* background: #252525;
    border: 1px solid #2F2F2F;
    border-radius: 15px;
    padding: 32px 0px 32px 13px;
    margin: 11px 0 0px 0; */
}
section#price-plans-section .price-list ul li {
    /* font-family: 'Anek Tamil'; */
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 26px;
    color: rgb(158 163 191 / 1);
    list-style: none;
    padding-left: 6px;
    text-align: left;
}
section#price-plans-section .price-inner-plan {
    border: 2px solid rgb(28 36 76 / 1);
    box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.13);
    border-radius: 2rem;
    padding: 40px 40px;
    --tw-shadow-colored: inset 0 -10px 40px rgb(26 34 72 / .5);
    --tw-shadow: inset 0 -10px 40px rgb(26 34 72 / .5);
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .5s;
    background: rgb(5 7 26 / 1);
    text-align: left;
    box-shadow: inset 0 -10px 40px #ad34b761;
}
section#price-plans-section .price-inner-plan h3 {
    font-weight: 300;
    font-size: .875rem;
    color: rgb(158 163 191 / 1);
}
section#price-plans-section .price-inner-plan h2.heading-pricing-colors {
    font-size: 1.125rem;
    color: white;
}
section#price-plans-section .price-heading-text p.text-sm.font-light {
    color: rgb(158 163 191 / 1);
    font-size: .875rem;
    margin: auto;
}
section#price-plans-section .nav-tabs .nav-link:hover {
    background: #ff10 !important;
}
section#price-plans-section .tab-pane {
    border: 0 !important;
    margin-top: 50px;
    background: none;
}
section#price-plans-section li.nav-item {
    text-align: center;
    margin: 0 auto !important;
    padding: 0 !important;
    display: flex;
}

section#price-plans-section .nav-tabs .nav-link.active {
    background: #ff10 !important;
    color: #AD34B7 !important;
}
section#price-plans-section .price-inner-plan:hover {
    border-color: rgb(28 36 76 / 1);
    transition: .9s;
    /* transform: translateY(30px); */
    box-shadow: 0px 0px 8px #ad34b761;
}

section#price-plans-section .price-inner-plan:hover .price-heading-text {
    border: 2px solid #AD34B7;
    box-shadow: inset 5px 11px 9px rgba(0, 0, 0, 0.18);
    transition: .9s;
    padding: 17px 20px;
    border-radius: 10px;
    margin-bottom: 13px;
}
.custom-price-button button {
    background: transparent;
    color: white;
    text-align: center;
    justify-content: center;
    padding: 12px 46px;
    border-color: #8100b4;
    border-width: 5px;
    border-radius: 17px;
    animation: glow 1s infinite !important;
}
@media only screen and (max-width: 768px) {
    section#price-plans-section li.nav-item{
      margin: 0 1px !important;
    }
    section#price-plans-section{
    padding: 30px 10px;
    }
    section#price-plans-section ul#myTab{
    width: 100%;
    }
}