section.faq-section .section-title h2 {
    color: white;
    font-size: 35px;
    margin: 27px 0px;
}


/* section.faq-section .faq-wrapper {
    border-radius: 20px;
    border: 1px solid black;
    padding: 11px 25px;
    text-align: justify;
    border: 2px solid rgb(28 36 76 / 1);
    box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.13);
    border-radius: 2rem;
    padding: 40px 40px;
    --tw-shadow-colored: inset 0 -10px 40px rgb(26 34 72 / .5);
    --tw-shadow: inset 0 -10px 40px rgb(26 34 72 / .5);
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .5s;
    background: rgb(5 7 26 / 1);
    text-align: left;
    box-shadow: inset 0 -10px 40px #ad34b761;
} */

section.faq-section .faq-wrapper .faq-item {
  color: white;
  border-radius: 20px;
  border: 1px solid black;
  text-align: justify;
  border: 2px solid #ad34b761;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.13);
  border-radius: 2rem;
  padding: 10px 26px;
  --tw-shadow-colored: inset 0 -10px 40px rgb(26 34 72 / .5);
  --tw-shadow: inset 0 -10px 40px rgb(26 34 72 / .5);
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .5s;
  background: rgb(5 7 26 / 0%);
  text-align: left;
  box-shadow: inset 0 -10px 40px #181820bf;
  margin-bottom: 20px;
  backdrop-filter: blur(1px);
}
section.faq-section .faq-wrapper .faq-item .question h4{
  margin: inherit;
  font-size: 22px;
}
section.faq-section .faq-wrapper .faq-item .question {
    padding: 14px 0px;
    font-size: 24px;
}
.faq-item .answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .faq-item .answer.show {
    max-height: 200px; /* Adjust max-height based on expected content */
  }
  
  .faq-item .icon {
    transition: transform 0.3s ease;
  }
  
  .faq-item .icon.rotate {
    transform: rotate(45deg); /* Rotates to form a cancel icon */
  }
  
  .faq-item .answer p {
    word-spacing: 4px;
    padding: 2px 20px;
    text-align: left;
    /* border-radius: 10px; */
    display: flex;
    margin: auto;
}
.faq-item .answer ul {
  margin: auto;
}
.faq-item .answer ul li {
  list-style: circle;
}
/* Add this to your App.css or Faqs.css */

.faq-item .question {
    position: relative;
    cursor: pointer;
    padding: 10px 0;
    font-size: 1.1rem;
    color: #fff; /* Adjust color as needed */
  }
  /* Hide the default cursor on hover */
  .question {
    cursor: none; /* Hide the standard cursor */
    position: relative;
  }

/* Custom "Open" text cursor */
.open-text-cursor {
  position: fixed; /* Follow the cursor exactly */
  pointer-events: none; /* Ensure it doesn’t interfere with clicks */
  font-weight: bold;
  font-size: 16px; /* Adjust font size as needed */
  display: none; /* Initially hidden */
  color: white;
  transition: top 0.1s ease, left 0.1s ease; /* Smooth follow transition */
    border: 2px solid rgb(28 36 76 / 1);
    border-radius: 2rem;
    padding: 19px 33px;
    --tw-shadow-colored: inset 0 -10px 40px rgb(26 34 72 / .5);
    --tw-shadow: inset 0 -10px 40px rgb(26 34 72 / .5);
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .5s;
    background: #be1bff94;
    text-align: left;
    box-shadow: inset 0 -10px 40px rgb(5 7 26 / 1);
    margin-bottom: 20px;
    z-index: 9999;
}

/* Show custom text cursor when hovering over question */
.question:hover .open-text-cursor {
  display: block;
}

  
  .faq-item .question:hover .open-label {
    display: inline-block;
  }
  
  .open-label {
    display: none;
    position: absolute;
    right: 26px;
    padding: 5px 10px;
    color: #AD34B7 ; /* Text color */
    border: 1px solid #AD34B7 ;
    border-radius: 12px;
    font-size: 0.9rem;
    background: rgba(255, 83, 104, 0.1); /* Background with slight transparency */
    box-shadow: 0px 0px 15px rgba(255, 83, 104, 0.6); /* Glow effect */
    transition: opacity 0.3s ease;
  }
  
  section.faq-section .faq-wrapper::before{
    position: absolute;
    content: '';
    display: ruby;
    height: 139px;
    width: 139px;
    right: 24px;
    margin-top: -95px;
    transform: translate(0px, 120px);
  animation: updown 2s ease-in-out infinite;
  background:  url('../../component/assets/daimond.png');
  background-repeat: round;
  }